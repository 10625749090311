export const GOOGLE_API_KEY = "AIzaSyCETIuL4bn7SXZHQlwJuQVNvgIQye4DHcE";

//export const API_URL = "https://localhost:443/api/auth/"
//export const ES_API_URL = "https://localhost:443/api/es/"
//export const JOBS_API_URL = "https://localhost:443/api/jobs/"
//export const DATA_PROTECTION_URL = "https://localhost:443/data-protection-information.html"

export const API_URL = process.env.REACT_APP_API_URL 
export const ES_API_URL = process.env.REACT_APP_ES_API_URL
export const JOBS_API_URL = process.env.REACT_APP_JOBS_API_URL
export const DATA_PROTECTION_URL = process.env.REACT_APP_DATA_PROTECTION_URL

export const BUTTON_INACTIV = "0"
export const BUTTON_ACTIV = "1"

export const PERSON_CHANGE_BUTTON_INIT = "Person bearbeiten";
export const PERSON_CHANGE_BUTTON_ACTIV = "Bearbeitung abbrechen";

export const TOMBSTONE_CHANGE_BUTTON_INIT = "Grabstein bearbeiten";
export const TOMBSTONE_CHANGE_BUTTON_ACTIV = "Bearbeitung abbrechen";

export const ASSESSMENT_CHANGE_BUTTON_INIT = "Bewertung bearbeiten";
export const ASSESSMENT_CHANGE_BUTTON_ACTIV = "Bearbeitung abbrechen";

export const CHANGE_BUTTON_INIT = "Bearbeiten";
export const CHANGE_BUTTON_ACTIV = "Bearbeitung abbrechen";

export const CEMENTERY_ALL = "_ALL_";
export const CEMENTERY_TOMBSTONE_PREFIX = "TOMBSTONE";
export const CEMENTERY_TOMBSTONE_MANAGER_ROLE = "TOMBSTONE_MANAGER";
export const CEMENTERY_PERSON_PREFIX = "PERSON";
export const CEMENTERY_PERSON_MANAGER_ROLE = "PERSON_MANAGER";

export const ROLE_USER = "USER";
export const ROLE_ADMIN = "ADMIN";
export const ROLE_MANAGER = "MANAGER";

export const ROLES = { "roles": [
/* 0 */ 	{"name": "LOGIN", "displayName": "Anmeldung", "description": "Benutzer darf sich anmelden"},	
/* 1 */		{"name": "ADMIN", "displayName": "Administrator", "description": "Alle Rechte ohne Einschränkung"},
/* 2 */		{"name": "MANAGER", "displayName": "Manager", "description": "Alle Rechte zur Bearbeitung des Friedhofs, von Grabsteinen und Personen"},
/* 3 */		{"name": "CEMENTRY_MANAGER", "displayName": "Friedhof", "description": "Alle Rechte zur Bearbeitung der Friedhofsinformationen"},
/* 4 */		{"name": "MAKER", "displayName": "Hersteller", "description": "Nutzer darf Hersteller bearbeiten"},
/* 5 */		{"name": "SELECTIONS", "displayName": "Auswahllisten", "description": "Nutzer darf Auswahllisten bearbeiten"},
/* 6 */		{"name": "TOMBSTONE_MANAGER", "displayName": "Grabstein Manager", "description": "Alle Rechte zur Bearbeitung von Grabsteinen"},

/* 7 		{"name": "TOMBSTONE_CREATOR", "displayName": "Anlage von Grabsteinen", "description": "Nutzer darf Grabsteine anlegen"}, */
/* 8 		{"name": "TOMBSTONE_APPROVER", "displayName": "Veröffentlichung von Grabsteinen", "description": "Nutzer darf Grabsteine veröffentlichen"}, */
/* 9 		{"name": "TOMBSTONE_GIS", "displayName": "GIS Daten", "description": "Nutzer darf GIS Daten bearbeiten"},*/

/* 7 */		{"name": "TOMBSTONE_INSCRIPTIONS", "displayName": "Inschriften", "description": "Inschriften verwalten"}, 
/* 8 		{"name": "TOMBSTONE_TRANSLATIONS", "displayName": "Übersetzungen", "description": "Übersetzungen verwalten"}, */

/* 10 */	{"name": "TOMBSTONE_IMAGES", "displayName": "Bilder", "description": "Nutzer darf Bilder hochladen und löschen, sowie den Fotografen bearbeiten"},
/* 11 */	{"name": "TOMBSTONE_COMMENT", "displayName": "Kommentar", "description": "Nutzer darf Kommentare bearbeiten"},
/* 12 */	{"name": "TOMBSTONE_CITATION", "displayName": "Zitate", "description": "Nutzer darf Zitate bearbeiten"},
/* 13 */	{"name": "TOMBSTONE_STIL", "displayName": "Stil", "description": "Nutzer darf Stil bearbeiten"},
/* 14 */	{"name": "TOMBSTONE_STYLISTIC", "displayName": "Stilmittel", "description": "Nutzer darf Stilmittel bearbeiten"},
/* 15 */	{"name": "TOMBSTONE_DIMENSION", "displayName": "Dimensionen", "description": "Nutzer darf Dimensionen bearbeiten"},
/* 16 */	{"name": "TOMBSTONE_TYPE", "displayName": "Grabsteintyp", "description": "Nutzer darf den Grabsteintyp bearbeiten"},
/* 17 */	{"name": "TOMBSTONE_DESCRIPTION", "displayName": "Beschreibung", "description": "Nutzer darf die Beschreibung des Grabsteins bearbeiten"},
/* 18 */	{"name": "TOMBSTONE_SYMBOLS", "displayName": "Darstellungen und Symbole", "description": "Nutzer darf die Darstellungen und Symbole des Grabsteins bearbeiten"},
/* 19 */	{"name": "TOMBSTONE_ORNAMENT", "displayName": "Schmuck und Ornamente", "description": "Nutzer darf Darstellungen und Symbole des Grabsteins bearbeiten"},
/* 20 */	{"name": "TOMBSTONE_MAKER", "displayName": "Hersteller", "description": "Nutzer darf den Hersteller des Grabsteins bearbeiten"},
/* 21 */	{"name": "TOMBSTONE_MATERIAL", "displayName": "Material", "description": "Nutzer darf das Material des Grabsteins bearbeiten"},
/* 22 */	{"name": "TOMBSTONE_CONDITIONS", "displayName": "Zustand", "description": "Nutzer darf den Zustands des Grabsteins bearbeiten"},
/* 23 */	{"name": "TOMBSTONE_RESTAURATION", "displayName": "Restaurierungen", "description": "Nutzer darf Restaurierungen des Grabsteins bearbeiten"},
/* 24 */	{"name": "TOMBSTONE_PERSONS", "displayName": "Personen auswählen", "description": "Nutzer darf Personen dem Grabstein zuordnen"},

/* 25 		{"name": "TOMBSTONE_REFERENCES", "displayName": "Quellen- und Literaturangaben", "description": "Nutzer darf Quellen- und Literaturangaben des Grabsteins bearbeiten"},*/
/* 26 		{"name": "TOMBSTONE_FOTOS", "displayName": "Foto Upload/Import", "description": "Nutzer darf Fotos hochladen"},*/
/* 27 		{"name": "TOMBSTONE_GIS_IMPORT", "displayName": "GEO Daten Upload/Import", "description": "Nutzer darf Vermessungsdaten hochladen"},*/

/* 28 */	{"name": "PERSON_MANAGER", "displayName": "Personen Manager", "description": "Alle Rechte zur Bearbeitung von Personen"},

/* 29 		{"name": "PERSON_NAMES", "displayName": "Namen", "description": "Nutzer darf Namen bearbeiten"},*/
/* 30 		{"name": "PERSON_DATES", "displayName": "Datumsangaben", "description": "Nutzer darf Datumsangaben bearbeiten"},*/
/* 31 		{"name": "PERSON_JOBS", "displayName": "Beruf", "description": "Nutzer darf die Berufe bearbeiten"},*/
/* 32 		{"name": "PERSON_RELATIVES", "displayName": "Verwandte", "description": "Nutzer darf Verwandte (Mutter, Vater, Kinder, Ehepartner) bearbeiten"},*/
/* 33 		{"name": "PERSON_BIOGRAPHY", "displayName": "Biografie", "description": "Nutzer darf die Biografie bearbeiten"},*/

/* 34 */	{"name": "PERONS_REFERENCES", "displayName": "Quellen- und Literaturangaben", "description": "Nutzer darf Quellen- und Literaturangaben zur Person bearbeiten"},

/* 35 		{"name": "PERONS_APPROVER", "displayName": "Veröffentlichung von Personen", "description": "Nutzer darf Personen veröffentlichen"},*/

/* 36 */	{"name": "REPORTS", "displayName": "Reporting", "description": "Nutzer darf Reporte erstellen und bearbeiten"}

]
}


export const AC_ADMIN_NAVIGATION = "AC_ADMIN_NAVIGATION";
export const AC_MANAGER_NAVIGATION = "AC_MANAGER_NAVIGATION";
export const AC_MANAGER = "AC_MANAGER";
export const AC_TOMBSTONE_MANAGER = "AC_TOMBSTONE_MANAGER";
export const AC_TOMBSTONE_MAKER = "AC_TOMBSTONE_MAKER";
export const AC_TOMBSTONE_MATERIAL = "AC_TOMBSTONE_MATERIAL";
export const AC_TOMBSTONE_INSCRIPTIONS = "AC_TOMBSTONE_INSCRIPTIONS";
export const AC_TOMBSTONE_IMAGES = "AC_TOMBSTONE_IMAGES";
export const AC_TOMBSTONE_COMMENT = "AC_TOMBSTONE_COMMENT";
export const AC_TOMBSTONE_CITATION = "AC_TOMBSTONE_CITATION";
export const AC_TOMBSTONE_STIL = "AC_TOMBSTONE_STIL";
export const AC_TOMBSTONE_STYLISTIC = "AC_TOMBSTONE_STYLISTIC";
export const AC_TOMBSTONE_DIMENSION = "AC_TOMBSTONE_DIMENSION";
export const AC_TOMBSTONE_TYPE = "AC_TOMBSTONE_TYPE";
export const AC_TOMBSTONE_DESCRIPTION = "AC_TOMBSTONE_DESCRIPTION";
export const AC_TOMBSTONE_SYMBOLS = "AC_TOMBSTONE_SYMBOLS";
export const AC_TOMBSTONE_ORNAMENT = "AC_TOMBSTONE_ORNAMENT";
export const AC_TOMBSTONE_CONDITIONS = "AC_TOMBSTONE_CONDITIONS";
export const AC_TOMBSTONE_RESTAURATION = "AC_TOMBSTONE_RESTAURATION";
export const AC_TOMBSTONE_PERSONS = "AC_TOMBSTONE_PERSONS";




export const AC_EDIT_CEMENTERY = "AC_EDIT_CEMENTERY";
export const AC_EDIT_MAKER = "AC_EDIT_MAKER";
export const AC_EDIT_SELECTIONS = "AC_EDIT_SELECTIONS";
export const AC_REPORTING = "AC_REPORTING";
export const AC_EDIT_REFERENCES_LIST = "AC_EDIT_REFERENCES_LIST";
export const AC_EDIT_FOOTNOTES = "AC_EDIT_FOOTNOTES";
export const AC_EDIT_ASSESSMENTS = "AC_EDIT_ASSESSMENTS";
export const AC_COPYRIGHTS = "AC_COPYRIGHTS";
export const AC_REPORT_CEMENTRY_PICTURES = "AC_REPORT_CEMENTRY_PICTURES";
export const AC_EDIT_TOMBSTONE_IMAGES_ONLY = "AC_EDIT_TOMBSTONE_IMAGES_ONLY";
export const AC_PERSON_MANAGER = "AC_PERSON_MANAGER";

export const center = {
	lat: 48.87586787482089,
	lng: 11.545149579351188
}

export const options = {
  fillColor: "lightblue",
  fillOpacity: 0.5,
  strokeColor: "green",
  strokeOpacity: 1,
  strokeWeight: 1,
  clickable: false,
  draggable: false,
  editable: false,
  geodesic: false,
  zIndex: 1,
}

export const mapStyles = {styles: [{ featureType: "poi", elementType: "labels", stylers: [{ visibility: "off" }]}],  disableDefaultUI: "true", zoomControl: "true", scaleControl:"true", fullscreenControl:"true", mapTypeControl:"true"}

export const tombstoneOptions = {
  fillColor: "black",
  fillOpacity: 1,
  strokeColor: "green",
  strokeOpacity: 1,
  strokeWeight: 1,
  clickable: true,
  draggable: false,
  editable: false,
  geodesic: false,
  showInfo: true,
  zIndex: 10,
  
}

export const tombstoneMarkerOptions = {
  fillColor: "red",
  fillOpacity: 1,
  strokeColor: "red",
  strokeOpacity: 1,
  strokeWeight: 1,
  clickable: true,
  draggable: false,
  editable: false,
  geodesic: false,
  showInfo: true,
  zIndex: 100,
  
}

export const tombstoneRedColor = {
  fillColor: "red",
  fillOpacity: 1,
  strokeColor: "red",
  strokeOpacity: 1,
  strokeWeight: 1,
  clickable: true,
  draggable: false,
  editable: false,
  geodesic: false,
  showInfo: true,
  zIndex: 100,
  
}

export const tombstoneYellowColor = {
  fillColor: "#d1a125",
  fillOpacity: 1,
  strokeColor: "#d1a125",
  strokeOpacity: 1,
  strokeWeight: 1,
  clickable: true,
  draggable: false,
  editable: false,
  geodesic: false,
  showInfo: true,
  zIndex: 100,
  
}

export const tombstoneGreenColor = {
  fillColor: "#1e8058",
  fillOpacity: 1,
  strokeColor: "#1e8058",
  strokeOpacity: 1,
  strokeWeight: 1,
  clickable: true,
  draggable: false,
  editable: false,
  geodesic: false,
  showInfo: true,
  zIndex: 100,
  
}

export const tombstoneGreyColor = {
  fillColor: "#a0a1a1",
  fillOpacity: 1,
  strokeColor: "#a0a1a1",
  strokeOpacity: 1,
  strokeWeight: 1,
  clickable: true,
  draggable: false,
  editable: false,
  geodesic: false,
  showInfo: true,
  zIndex: 100,
  
}

export const tombstonePurpleColor = {
  fillColor: "#a751ac",
  fillOpacity: 1,
  strokeColor: "#a751ac",
  strokeOpacity: 1,
  strokeWeight: 1,
  clickable: true,
  draggable: false,
  editable: false,
  geodesic: false,
  showInfo: true,
  zIndex: 100,
  
}

export const tombstoneBlueColor = {
  fillColor: "#173bc9",
  fillOpacity: 1,
  strokeColor: "#173bc9",
  strokeOpacity: 1,
  strokeWeight: 1,
  clickable: true,
  draggable: false,
  editable: false,
  geodesic: false,
  showInfo: true,
  zIndex: 100,
  
}

export const tombstoneBlackColor = {
  fillColor: "black",
  fillOpacity: 1,
  strokeColor: "black",
  strokeOpacity: 1,
  strokeWeight: 1,
  clickable: true,
  draggable: false,
  editable: false,
  geodesic: false,
  showInfo: true,
  zIndex: 100,
  
}

export const color15501599 = {
  fillColor: "#cd5b7d",
  fillOpacity: 1,
  strokeColor: "#cd5b7d",
  strokeOpacity: 1,
  strokeWeight: 1,
  clickable: true,
  draggable: false,
  editable: false,
  geodesic: false,
  showInfo: true,
  zIndex: 100, 
}
export const color16001649 = {
  fillColor: "#e48544",
  fillOpacity: 1,
  strokeColor: "#e48544",
  strokeOpacity: 1,
  strokeWeight: 1,
  clickable: true,
  draggable: false,
  editable: false,
  geodesic: false,
  showInfo: true,
  zIndex: 100, 
}
export const color16501699 = {
  fillColor: "#eda023",
  fillOpacity: 1,
  strokeColor: "#eda023",
  strokeOpacity: 1,
  strokeWeight: 1,
  clickable: true,
  draggable: false,
  editable: false,
  geodesic: false,
  showInfo: true,
  zIndex: 100, 
}
export const color17001749 = {
  fillColor: "#2fb91f",
  fillOpacity: 1,
  strokeColor: "#2fb91f",
  strokeOpacity: 1,
  strokeWeight: 1,
  clickable: true,
  draggable: false,
  editable: false,
  geodesic: false,
  showInfo: true,
  zIndex: 100, 
}
export const color17501799 = {
  fillColor: "#1fb3b9",
  fillOpacity: 1,
  strokeColor: "#1fb3b9",
  strokeOpacity: 1,
  strokeWeight: 1,
  clickable: true,
  draggable: false,
  editable: false,
  geodesic: false,
  showInfo: true,
  zIndex: 100, 
}
export const color18001849 = {
  fillColor: "#1fb98b",
  fillOpacity: 1,
  strokeColor: "#1fb98b",
  strokeOpacity: 1,
  strokeWeight: 1,
  clickable: true,
  draggable: false,
  editable: false,
  geodesic: false,
  showInfo: true,
  zIndex: 100, 
}
export const color18501899 = {
  fillColor: "#b91fb6",
  fillOpacity: 1,
  strokeColor: "#b91fb6",
  strokeOpacity: 1,
  strokeWeight: 1,
  clickable: true,
  draggable: false,
  editable: false,
  geodesic: false,
  showInfo: true,
  zIndex: 100, 
}
export const color1900 = {
  fillColor: "#f07ced",
  fillOpacity: 1,
  strokeColor: "#f07ced",
  strokeOpacity: 1,
  strokeWeight: 1,
  clickable: true,
  draggable: false,
  editable: false,
  geodesic: false,
  showInfo: true,
  zIndex: 100, 
}














export const infoBoxOptions = { 
	closeBoxURL: '', 
	enableEventPropagation: true 
};

export const DialogWidth = {
  XS: "xs",
  SM: "sm",
  MD: "md",
  LG: "lg",
  XL: "xl"
}

export const ConditionDamageType = {
  	StoneDamage: "Schäden am Stein",
	ConstructionDamage: "Konstruktive Schäden", 
	DesacrationsDamage: "Schäden im Kontext von Schändungen"
}

export const consentText = "Um die Karte nutzen zu können, müssen Sie den Datenschutz Datenschutzerklärungen von Google zustimmen."


