import React, { useEffect, useState } from 'react'
import { Row, Col } from "react-bootstrap";

import Lightbox from "yet-another-react-lightbox";
import Counter from "yet-another-react-lightbox/plugins/counter";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "yet-another-react-lightbox/plugins/counter.css";

export default function ImageGalery(props) {

	const [open, setOpen] = React.useState(false);
	const { photos } = props;
	const [slides, setSlides] = useState([]);

	useEffect(() => {
		let imageArray = []
		photos.map((item, key) =>
			imageArray[key] = { "src": item.url }
		)
		setSlides(imageArray);
	}, []);


	return (
		<Row>
		
				{photos.map((dd, key) =>
					
					<Col md={2} style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
						<div key={key} className="imageGalery" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
							<div class="image-galerie-container">
								<img key={key} src={dd.url} alt='Bild' onClick={() => setOpen(true)} />{' '}
							</div>
							{console.log(dd.right.rightChoices)}
							<span style={{ fontSize: '10px', color: 'black' }}>{dd.right.rightChoices.length > 0 && dd.right.rightChoices[0] !== null ? dd.right.rightChoices[0].value + ' | ' + dd.right.rightChoices[0].comment : ""} </span>

						</div>
					</Col>
				)}
				{open && (
					<Lightbox
						open={open}
						close={() => setOpen(false)}
						slides={slides}
						plugins={[Counter, Thumbnails]}
						counter={{ container: { style: { top: "unset", bottom: 0 } } }}
					/>
					
				)}
				
		</Row>
	)
}


