import React, { useState, useEffect } from "react";

import { Container, Row, Col, Breadcrumb } from "react-bootstrap";

import ElasticSearchService from "../../services/elasticSearch.service";
import AuthService from "../../services/auth.service";

import CementeryListDetailElement from "./details/cementeryListDetailElement";
import CementeryMapDetailElement from "./details/cementeryMapDetailElement";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { handleStorage } from '../../helpers/jfb'
import * as Constants from "../../components/constants";

export default function Cementeries() {
	
	const [isLoadingCementries, setLoadingCementries] = useState(true);
	const [cementries, setCementries] = useState([]);
	const [cementryFilter, setCementryFilter] = useState([]);
	const [successful, setSuccessful] = useState(false);	
	const [message, setMessage] = useState("");
	const [selectedCementry, setSelectedCementry] = useState("0");
	const [googleMapConsent] = useState(handleStorage("googleMapConsent").length!==0 ? Boolean(handleStorage("googleMapConsent")) : false);
	

	function handleCementryChange(e) {
		
		let id = e.id;
		setSelectedCementry(id);

		localStorage.setItem("cementery", JSON.stringify(id));
		loadFilteredCementries(id);
		
	}
	
	function loadFilteredCementries(id){
		ElasticSearchService.loadPublicCementries().then(
			response => {
				if(id==="0"){
					setCementryFilter(response.data);
				}
				else{
					
					let result = response.data.find(cementery => cementery.id === id)
					let filteredArray = [];
					filteredArray.push(result);
					
					setCementryFilter(filteredArray);
				}

				
			},
			error => {
				const resMessage =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();
	
					setMessage(resMessage);
					setSuccessful(false);
					setLoadingCementries(false);
				}
			);

	}
				
	useEffect(() => {
	
		if(isLoadingCementries) {
			
			const user = AuthService.getCurrentUser();
			let onlyPublished = user ? "0" : "-2";
			
			ElasticSearchService.loadCementriesForAutoComplete(onlyPublished).then(
			response => {
				
				setCementries(response.data);
				setLoadingCementries(false);
				setSuccessful(true)
			
			},
			error => {
				const resMessage =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();
	
					setMessage(resMessage);
					setSuccessful(false);
					setLoadingCementries(false);
				}
			);
			setSelectedCementry("0")
			loadFilteredCementries("0")
		}

	}, [isLoadingCementries]);
			
	return (
		<Container fluid="sm">
		
			<Row className="top-spacer">
				<Col>
					<Breadcrumb className="breadcrumb-style">
					  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
					  <Breadcrumb.Item active>Historische Friedhöfe ....</Breadcrumb.Item>
					</Breadcrumb>
				</Col>
			</Row>
			
			<Row className="text-spacer">
				<Col><h3>Historische jüdische Friedhöfe in Bayern</h3></Col>
			</Row>
			<Row className="text-spacer">
				<Col>
				<p>Seit den 1980er Jahren stehen jüdische Friedhöfe in Bayern unter Denkmalschutz. Die Denkmalliste des Bayerischen Landesamts für Denkmalpflege umfasst aktuell 124 jüdische Friedhöfe. Die abgegangenen jüdischen Friedhöfe, die KZ-Friedhöfe und Friedhöfe für Displaced Persons sind hier nicht mitgezählt. Die Vertreibung der Juden aus dem Herzogtum Bayern im 15. Jahrhundert führte dazu, dass die Juden sich vorwiegend in den Reichsritterschaften in Franken und Schwaben ansiedelten. Dort sind auch heute noch die meisten jüdischen Friedhöfe zu finden: 44 in Unterfranken, 28 in Mittelfranken, 20 in Oberfranken, 20 in Schwaben – dagegen nur acht in der Oberpfalz, drei in Oberbayern und einer in Niederbayern. In all diesen Friedhöfen zählen wir einen Bestand von ca. 80.000 Grabmälern. 13 Friedhöfe dienen den aktiven jüdischen Kultusgemeinden in Bayern als Begräbnisstätte, 111 Friedhöfe sind geschlossen. Die Friedhöfe sind Eigentum des Landesverbandes der Israelitischen Kultusgemeinden in Bayern. Mit dem Landesverband werden sämtliche Maßnahmen in den Friedhöfen abgestimmt.</p>

				<p>Nach jüdischer Glaubenslehre gehören der Grabstein und die Grabstelle dem Verstorbenen „auf ewig“. Die Gräber werden daher von den jüdischen Gemeinden nicht aufgelassen und neu belegt. Doch im Zuge zahlreicher antijüdischer Pogrome und Vertreibungen im ausgehenden Mittelalter wurden die Friedhöfe der Juden in Bayern vollständig zerstört. So stammen die ältesten erhaltenen Friedhöfe, dazu zählen Rödelsee, Kleinsteinach und Zeckern, aus dem 15. Jahrhundert. In der frühen Neuzeit wurden meist große, ortsferne Verbandsfriedhöfe angelegt, in denen mehrere Gemeinden ihre Toten bestatteten. Erst im 19. Jahrhundert konnten die jüdischen Gemeinden zunehmend eigene Friedhöfe in Ortsnähe einrichten. Etwa die Hälfte der heute vorhandenen Friedhöfe wurden in dieser Zeit gegründet.</p> 
				
				<p>Zu allen Zeiten wurden jüdische Friedhöfe geschändet, 
				im größten Umfang aber in der Zeit des Nationalsozialismus. 
				Das Landesamt für Denkmalpflege hat eine zeitlich befristete Forschungsstelle zu diesem Thema eingerichtet, 
				hierzu Näheres unter: Projekt <a href="https://www.blfd.bayern.de/information-service/projekte/net-olam/">„Net Olam. 
				Jüdische Friedhöfe im Fokus von Antisemitismus und Prävention“</a>.</p>

				</Col>
			</Row>
			<Row className="top-spacer"/>
			
			
			{googleMapConsent ? (
					<CementeryMapDetailElement cementeries={cementryFilter} published={false} />
				):(
					<Row className="top-spacer text-spacer">
						<Col>
						
								<div className="consent-info" >{Constants.consentText}<br/>
							<strong>Den Button finden Sie unten auf dieser Seite!</strong></div>

						</Col>	
					</Row>
				)};
			
		
			<Row className="top-spacer text-spacer">
				<Col><h4>Vollständige Liste der Friedhöfe</h4></Col>
			</Row>
			{!isLoadingCementries && successful ? (
				
			<Row className="text-spacer">
							<Col>
								<Autocomplete
										value={cementries.find(cementery => cementery.id === selectedCementry)}
										onChange={(event, newValue) => { handleCementryChange(newValue); }}
										disableClearable={true}
										id="combo-box-cementries"
										options={cementries}
										
										renderInput={(params) => <TextField {...params} label="Suche nach einem Friedhof" />}
									/>
					

							</Col>
			</Row>
						
						
			): (
							<Row>
								<Col>Loading...</Col>
							</Row>
						)}
			
			<CementeryListDetailElement cementeries={cementryFilter} published={false}/>
			{message && (
					<div className="form-group">
						<div className={successful ? "alert alert-success": "alert alert-danger"} role="alert">{message}</div>
					</div>
			)}
		
		</Container>
	);
}