export function DisplayTextWithLinks( {text}) {
  // URLs erkennen und umwandeln
  const linkifiedText = text.split(' ').map((word, index) => {
    const urlPattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    const isUrl = urlPattern.test(word);
    return isUrl ? <a key={index} href={word} target="_blank" rel="noopener noreferrer">{word}</a> : <span key={index}> {word} </span>;
  });

  return linkifiedText;
}