//eslint-disable-next-line
Array.prototype.any = function(filterFun) { return this.filter(filterFun).length > 0 }
//Array.prototype.remove = function(index) { this.splice(index, 1); return this } //eslint-disable-next-line


export const batOrBen = data => {
	
	
	if (data == null ){
        return " "
    }
	
	if(data === "männlich"){
		
		return " ben ";
	}
	
	if(data === "weiblich"){
		return " bat ";
	}
	
	return " " 
}


export function renderDateFromdateParts(year, month, day){
	var yearValue = "";
	var monthValue = "";
	var dayValue = "";
	
	if(year){
	 	yearValue = year;
	}
	else{
		yearValue = "XXXX";
	}

	if (month){
		if(month.length === 1){
			monthValue = "0" + month;	
		}
		else{
			monthValue = month;
		}		
	}
	else{
		monthValue = "XX";
	}
	
	if (day){
		if(day.length === 1){
			dayValue = "0" + day;	
		}
		else{
			dayValue = day;
		}		
	}
	else{
		dayValue = "XX";
	}
	
	return dayValue + "." + monthValue + "." + yearValue
}

export function renderDate(date) {
    if (!date || isNaN(Date.parse(date)))
        return "?"
    var [year, month, day] = date.split("-")
    if (month)
        if (month.length === 1) month = "0" + month
    if (day)
        if (day.length === 1) day = "0" + day
    return day + "." + month + "." + year
}

export function renderDateFromObject(data){
	 if (data == null ){
        return ""
    }
    var {heiratsdatum_jahr, heiratsdatum_monat, heiratsdatum_tag} = data

	
	if (heiratsdatum_jahr && heiratsdatum_monat && heiratsdatum_tag){
		if (heiratsdatum_monat)
        	if (heiratsdatum_monat.length === 1) heiratsdatum_monat = "0" + heiratsdatum_monat
    	if (heiratsdatum_tag)
        	if (heiratsdatum_tag.length === 1) heiratsdatum_tag = "0" + heiratsdatum_tag
		
		return heiratsdatum_tag + "." + heiratsdatum_monat + "." + heiratsdatum_jahr
	}
	
  }


export const personToString = data => {
    if (data == null ){
        return ""
    }
   
    var {buergerlicher_name, buergerlicher_vorname, hebraeischer_vorname, hebraeischer_name, geburtsname, geburtsdatum, sterbedatum, herkunftsort, wohnort, geschlecht} = data
    
    
    
    
    
	let name = "";
	
	if(buergerlicher_vorname && buergerlicher_name){
		
		name = buergerlicher_vorname +" "+ buergerlicher_name;
	}
	else{
		if(!buergerlicher_vorname && buergerlicher_name){
			
			name = buergerlicher_name;
		}
		else{
			if(buergerlicher_vorname && !buergerlicher_name){
				
				name = buergerlicher_vorname;
			}
		}

	}
	
	if(name === "" || name.length ===0 || name.indexOf("null")>-1){
		if (hebraeischer_vorname && hebraeischer_name ){
			name = hebraeischer_vorname +" "+ batOrBen(geschlecht) +" "+ hebraeischer_name;
		}
		else{
			if (!hebraeischer_vorname && hebraeischer_name ){
				name = hebraeischer_name;
			}else{
				name = hebraeischer_vorname;
			}
		}
	}

	var ret = []
	if(name === null){
		name = "Unbekannte Person";
	}
	
    ret.push(name)
    if (geburtsname)
        ret.push("geb. " + geburtsname)
    ret.push(ret.pop() + " (" + renderDate(geburtsdatum) + " - " + renderDate(sterbedatum) + ")")
    var ort = wohnort || herkunftsort || ""
    if (ort !== "") {
       ret.push("aus " + ort)
    }
    return ret.join(", ")
}

export const personName = data => {
	
	console.log(data)

    if (data == null ){
        return ""
    }
    var {buergerlicher_name, buergerlicher_vorname, hebraeischer_vorname, hebraeischer_name, geburtsname, geschlecht} = data
    
	let name = "";
	
	if(buergerlicher_vorname && buergerlicher_name){
		
		name = buergerlicher_vorname +" "+ buergerlicher_name;
	}
	else{
		if(!buergerlicher_vorname && buergerlicher_name){
		
			name = buergerlicher_name;
		}
		else{
			if(buergerlicher_vorname && !buergerlicher_name){
			
				name = buergerlicher_vorname;
			}
		}
	}
	
	if(!name){
		if (hebraeischer_vorname && hebraeischer_name ){
			name = hebraeischer_vorname +" "+ batOrBen(geschlecht) +" "+ hebraeischer_name;
		}
		else{
			if (!hebraeischer_vorname && hebraeischer_name ){
				name = hebraeischer_name;
			}
			else{
			  if(hebraeischer_vorname && !hebraeischer_name){
				name = hebraeischer_vorname;
				}
			}
		}
	}
	var ret = []
    ret.push(name)
    if (geburtsname)
        ret.push("geb. " + geburtsname)
 
    return ret.join(", ")
}

export const personIds = data => {

    if (data == null ){
        return ""
    }
    var {persons} = data
    
	let ret = "";
	
	for (let i = 0; i< persons.length; i++){
		ret = ret + persons[i].personId;
		if(i< persons.length-1){
			ret = ret + " und ";
		}
	}
	return ret;
}

export const tombstoneNumbers = data => {

    if (data == null ){
        return ""
    }
    var {tombstoneNumbers} = data
    
	let ret = "";
	
	for (let i = 0; i< tombstoneNumbers.length; i++){
		ret = ret + tombstoneNumbers[i].nummer + " " +tombstoneNumbers[i].kommentar;
		if(i< tombstoneNumbers.length-1){
			ret = ret + ", ";
		}
	}
	return ret;
}

export const checkDataExist = data => {
    if (data == null ){
        return false;
    }else{
		return true;
	}
}

export const checkArray = data => {
    if (data == null ){
        return false;
    }
	if(data.length === 1 && data[0] === ""){
		return false;
	}
	if(data.length >= 1 ){
		return true;
	}
}

export function isEmpty(str) {
    return (!str || str.length === 0 );
}

export function isBlank(str) {
    return (!str || /^\s*$/.test(str));
}

export function getStringValue(value, defaultValue) {
	if (isEmpty(value) || isBlank(value)) return defaultValue;
	else return value;
}

export function getStringDate(date) {
	
	// yyyy-MM-dd
	return date.getFullYear() + "-" 
			+ (date.getMonth() < 9 ? "0" : "") + (date.getMonth() + 1) + "-" 
			+  (date.getDate() < 10 ? "0" : "") + (date.getDate());
}

export function getStringDateNow() {
	
	let date = new Date();
	
	return getStringDate(date);

}

export function getDateByDayMonthYear(day, month, year) {
	
	return new Date(year, month, day);
}
