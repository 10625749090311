
import React from 'react';
import ReactDOM from 'react-dom';
import JFBApp from './JFBApp';
import { BrowserRouter } from "react-router-dom";
import * as serviceWorker from './registerServiceWorker';


ReactDOM.render(
	
 <BrowserRouter>
   	 <JFBApp/>  	 
 </BrowserRouter>, document.getElementById("root")
 
);

serviceWorker.unregister();