import React, { useState, useEffect } from "react";
import { Container, Row, Col, Breadcrumb, Form } from "react-bootstrap";

import ElasticSearchService from "../../services/elasticSearch.service";
import AuthService from "../../services/auth.service";

import { handleStorage } from '../../helpers/jfb'
import { personToString } from '../../helpers/util'

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import FileSaver from 'file-saver';
import * as Constants from "../../components/constants";
import ACService from "../../helpers/accessValidation.js"
import TombstoneMapDetailElement from "./details/tombstoneMapDetailElement2";


import styled from "styled-components";

export default function Tombstones() {

	const [user] = useState(AuthService.getCurrentUser());


	const [localStorageCementery, setLocalStorageCementery] = useState(handleStorage("cementery").length !== 0 && handleStorage("cementery") !== '0' ? handleStorage("cementery") : '1');
	const [filteredTombstoneIds, setFilteredTombstoneIds] = useState(handleStorage('tombstoneFilter'));

	const [isLoadingCementeries, setLoadingCementeries] = useState(true);

	const [cementeries, setCementeries] = useState([{}]);

	const [selectedCementery, setSelectedCementery] = useState([]);

	const [isLoadingTombstones, setLoadingTombstones] = useState(true);
	const [tombstones, setTombstones] = useState([]);
	const [tombstoneCounter, setTombstoneCounter] = useState(0);

	const [tombstoneIds, setTombstoneIds] = useState([]);
	const [isLoadingTombstoneIds, setLoadingTombstoneIds] = useState(false);

	const [successful, setSuccessful] = useState(false);
	const [message, setMessage] = useState("");

	const [managerLinkTombstone, setManagerLinkTombstone] = useState(false);
	const [managerLinkPerson, setManagerLinkPerson] = useState(false)
	const [googleMapConsent] = useState(handleStorage("googleMapConsent").length !== 0 ? Boolean(handleStorage("googleMapConsent")) : false);



	const PdfAnchor = styled.a`
		cursor: pointer;
	`;

	const [tombstonesForMap, setTombstonesForMap] = useState([]);

	function createPdf(cementryId, tombstoneId) {

		setMessage("");

		ElasticSearchService.createTombstonePdf(cementryId, tombstoneId).then(
			response => {

				let fileName = cementryId + "-" + tombstoneId + ".pdf"

				FileSaver.saveAs(new Blob([response.data], { type: 'application/pdf' }), fileName);
			},
			error => {
				const resMessage =
					(error.response &&
						error.response.data &&
						error.response.data.message) ||
					error.message ||
					error.toString();

				setMessage(resMessage);
			}
		);
	}

	function getSelectedCementery(cementeryId) {
		ElasticSearchService.getCementry(cementeryId).then(
			response => {

				setSelectedCementery(response.data);
			},
			error => {
				const resMessage =
					(error.response &&
						error.response.data &&
						error.response.data.message) ||
					error.message ||
					error.toString();

				setMessage(resMessage);

			}
		);
	}

	function handleCementryChange(e) {

		if (e !== null) {
			let id = e.id;
			setTombstones([]);
			setTombstoneCounter(0);

			localStorage.setItem("tombstoneFilter", JSON.stringify([]));
			localStorage.setItem("cementery", JSON.stringify(id));
			setLocalStorageCementery(id)

			setLoadingTombstoneIds(true);
			setLoadingTombstones(true);

			loadAllTombstonesIds(id);
			loadAllTombstones(id);


			getSelectedCementery(id);
			setFilteredTombstoneIds([]);

		}

		setManagerLinkTombstone(ACService.accessValidation(user, [Constants.AC_ADMIN_NAVIGATION, Constants.AC_MANAGER_NAVIGATION], e.label));
		setManagerLinkPerson(ACService.accessValidation(user, [Constants.AC_ADMIN_NAVIGATION, Constants.AC_MANAGER, Constants.AC_PERSON_MANAGER], e.label));
	}

	function handleTombstoneFilterChange(e) {
		setLoadingTombstones(true);
		if (e.length !== 0) {
			let filteredTombstones = e;
			setTombstones([]);
			filterTombstones(filteredTombstones);
		}
		else {
			localStorage.setItem("tombstoneFilter", JSON.stringify([]));
			setTombstones([]);
			setFilteredTombstoneIds([]);
			loadAllTombstones(localStorageCementery);
		}

	}

	function filterTombstones(tombstoneIds) {
		setFilteredTombstoneIds(tombstoneIds);

		ElasticSearchService.filterTombstones(localStorageCementery, tombstoneIds).then(
			response => {
				setTombstones(response.data.tombstones);
				setLoadingTombstones(false);
				setTombstoneCounter(response.data.tombstones.length);
			},
			error => {
				const resMessage =
					(error.response &&
						error.response.data &&
						error.response.data.message) ||
					error.message ||
					error.toString();

				setMessage(resMessage);
				setSuccessful(false);
				setLoadingTombstones(false);
			}
		);

		localStorage.setItem("tombstoneFilter", JSON.stringify(tombstoneIds));
	}

	function loadAllTombstones(cemeteryId) {
		ElasticSearchService.loadTombstones(cemeteryId).then(
			response => {
				setTombstones(response.data.tombstones);
				setTombstoneCounter(response.data.numberOfTombstones);
				setTombstonesForMap(response.data.tombstones)

				setLoadingTombstones(false);
				setSuccessful(true);

			},
			error => {
				const resMessage =
					(error.response &&
						error.response.data &&
						error.response.data.message) ||
					error.message ||
					error.toString();

				setMessage(resMessage);
				setSuccessful(false);
				setLoadingTombstones(false);
			}
		);

	}

	function loadAllTombstonesForMap(cemeteryId) {
		ElasticSearchService.loadTombstones(cemeteryId).then(
			response => {
				setTombstonesForMap(response.data.tombstones)

			},
			error => {
				const resMessage =
					(error.response &&
						error.response.data &&
						error.response.data.message) ||
					error.message ||
					error.toString();

				setMessage(resMessage);

			}
		);
	}

	function loadAllTombstonesIds(cemeteryId) {
		ElasticSearchService.loadTombstoneIds(cemeteryId).then(
			response => {
				setTombstoneIds(response.data);
				setLoadingTombstoneIds(false)
				setSuccessful(true)
			},
			error => {
				const resMessage =
					(error.response &&
						error.response.data &&
						error.response.data.message) ||
					error.message ||
					error.toString();

				setMessage(resMessage);
				setSuccessful(false);
				setLoadingTombstoneIds(false)
			}
		);
	}


	function getCementeryName(cementeries, cementeryId) {
		return cementeries.find(cementery => cementery.id === cementeryId).label;
	}

	function getCementery(cementeries, cementeryId) {
		return cementeries.find(cementery => cementery.id === cementeryId);
	}

	useEffect(() => {

		const user = AuthService.getCurrentUser();


		let onlyPublished = user ? "0" : "-2";

		if (isLoadingCementeries) {
			ElasticSearchService.loadCementriesForAutoComplete(onlyPublished).then(
				response => {
					setCementeries(response.data);

					setSuccessful(true);
					setLoadingCementeries(false);

					setManagerLinkTombstone(ACService.accessValidation(user, [Constants.AC_ADMIN_NAVIGATION, Constants.AC_MANAGER_NAVIGATION], getCementeryName(response.data, localStorageCementery)));
					setManagerLinkPerson(ACService.accessValidation(user, [Constants.AC_ADMIN_NAVIGATION, Constants.AC_MANAGER, Constants.AC_PERSON_MANAGER], getCementeryName(response.data, localStorageCementery)));
				},
				error => {
					const resMessage =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();

					setMessage(resMessage);
					setSuccessful(false);
					setLoadingCementeries(false);
				}
			);

		}


		if (isLoadingTombstoneIds || localStorageCementery.length !== 0) {
			loadAllTombstonesIds(localStorageCementery);

		}


		if (filteredTombstoneIds.length !== 0) {
			filterTombstones(filteredTombstoneIds);
			loadAllTombstonesForMap(localStorageCementery)
		}
		else {
			if (localStorageCementery.length !== 0) {
				loadAllTombstones(localStorageCementery)

			}

		}

		if (localStorageCementery.length !== 0) {
			getSelectedCementery(localStorageCementery);

		}

		if (user) {

		}


	}, []);



	return (
		<Container fluid="xxl">



			{successful && !isLoadingCementeries && (

				<div className="top-spacer text-spacer">

					<Row>
						<Col>
							<Breadcrumb className="breadcrumb-style">
								<Breadcrumb.Item href="/">Home</Breadcrumb.Item>
								<Breadcrumb.Item href="/cemeteries/">Historische Friedhöfe</Breadcrumb.Item>
								<Breadcrumb.Item href={'/cemetery/' + localStorageCementery}> {getCementeryName(cementeries, localStorageCementery)}</Breadcrumb.Item>
								<Breadcrumb.Item active>Grabsteinlisten</Breadcrumb.Item>
							</Breadcrumb>
						</Col>
					</Row>
					<Row>
						<Col><h3>Grabsteinliste</h3></Col>
					</Row>

					<Row className="top-spacer">
						<Col>
							<Form.Group as={Col} controlId="CementryChoice">
								<Autocomplete
									value={getCementery(cementeries, localStorageCementery)}
									onChange={(event, newValue) => { handleCementryChange(newValue); }}
									disableClearable={true}
									id="combo-box-cementries"
									options={cementeries}
									renderInput={(params) => <TextField {...params} label="Bitte wählen Sie einen Friedhof" />}
								/>
							</Form.Group>

						</Col>
					</Row>
					{(filteredTombstoneIds.length !== 0 || localStorageCementery.length !== 0) && (
						<Row className="top-spacer">
							<Col>
								<Form.Group as={Col} controlId="TombstoneFilter">
									<Autocomplete
										multiple
										value={filteredTombstoneIds}
										id="multiple-limit-tags"
										options={tombstoneIds}
										isOptionEqualToValue={(option, value) => option.id === value.id}
										getOptionLabel={(option) => option.label}
										onChange={(event, newValue) => { handleTombstoneFilterChange(newValue) }}
										renderInput={(params) => (<TextField {...params}
											label="Grabsteinnummern"
											placeholder="Geben Sie eine Grabsteinnummer ein..." />)}
										sx={{ width: '100%' }}
									/>
								</Form.Group>
							</Col>
						</Row>
					)}


					<Row className="top-spacer">
						<Col>
							Grabsteine: {tombstoneCounter}
						</Col>
					</Row>

					{!isLoadingTombstones && (

						<Row>
							<Col>
								{googleMapConsent ? (
									<TombstoneMapDetailElement cementery={selectedCementery} tombstones={tombstonesForMap} zoom={18} tombstoneMarker={tombstones.length === tombstonesForMap.length ? null : tombstones} />
								) : (
									<div className="consent-info" >Um die Karte nutzen zu können müssen den Datenschutz Datenschutzerklärungen von Google zustimmen.<br />
										<strong>Den Button finden Sie unten auf dieser Seite!</strong></div>
								)}
							</Col>
						</Row>
					)}

					<Row>
						<Col>
							<hr />
						</Col>
					</Row>
					<Row>
						<Col>
							Für Details klicken Sie bitte auf die Grabstein-ID oder den Namen der Person
						</Col>
					</Row>
					<Row>
						<Col>
							{localStorageCementery.length !== 0 && isLoadingTombstones && (
								<div className="alert alert-success">
									Daten der Grabsteine werden geladen
								</div>
							)}
							{(filteredTombstoneIds.length !== 0 || tombstones.length !== 0) && (
								<div>


									{tombstones.map((tombstone, id) => (
										<div key={id}>


											<Row>
												<Col>
													<hr />
												</Col>
											</Row>
											<Row>
												<Col className="col-auto me-auto" >
													<img width={64} height={64} className="mr-3" src={tombstone.bilder && tombstone.bilder[0] && tombstone.bilder[0].tumbnailImagePath ? tombstone.bilder[0].tumbnailImagePath : ""} alt="kein Bild vorhanden" />
												</Col>
												<Col>
													<p><strong> <a href={"/tombstoneDetails/" + tombstone.cementry + "/" + tombstone.grabstein.grabsteinnummer}>Grabstein-ID: {tombstone.grabstein.grabsteinnummer}</a></strong></p>
													{tombstone.persons.map((person, id3) => (
														<div key={id3}>
															<a href={"/personDetails/" + tombstone.cementry + "/" + person.id} >{personToString(person)}</a>
														</div>
													))}
												</Col>

												<Col className="col-auto" >

													{managerLinkTombstone && (
														<a href={"/management/tombstone/" + tombstone.cementry + "/" + tombstone.grabstein.grabsteinnummer} className="manager-link" >Grabstein bearbeiten</a>

													)}


													{managerLinkPerson && (
														tombstone.persons.map((person, id3) => (
															<div key={id3}>
																<a href={"/management/person/" + person.cementry + "/" + person.id} className="manager-link" >Person bearbeiten</a>
															</div>
														))
													)}
												</Col>
												<Col className="col-auto" >
													<PdfAnchor onClick={(e) => createPdf(tombstone.cementry, tombstone.grabstein.grabsteinnummer)} >PDF</PdfAnchor>
												</Col>
											</Row>
										</div>
									))}
								</div>
							)}
						</Col>
					</Row>

					<div className="areaButtonLine"></div>

				</div>
			)}

			{message && (
				<div className="form-group">
					<div className={successful ? "alert alert-success" : "alert alert-danger"} role="alert">{message}</div>
				</div>
			)}





		</Container>

	);
}