import React, { Component } from 'react';
import { Container, Row, Col, Button, Breadcrumb, Figure } from "react-bootstrap";
import ElasticSearchService from "../../services/elasticSearch.service";
import AuthService from "../../services/auth.service";
import ACService from "../../helpers/accessValidation.js"
import * as Constants from "../../components/constants";
import FileSaver from 'file-saver';


export default class MakerList extends Component {

	constructor(props) {
		super(props);

		this.state = {
			makers: {},
			isMakersLoading: true,
			readOnly: true,
			showManagerLinks: false,
			currentUser: "",
		}
		
		this.createMakerPdf = this.createMakerPdf.bind(this);

	}

	createMakerPdf(maker) {

			

			ElasticSearchService.createMakerPdf(maker.id).then(
				response => {

					let fileName = maker.displayName + ".pdf"

					FileSaver.saveAs(new Blob([response.data], { type: 'application/pdf' }), fileName);
				},
				error => {
					const resMessage =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();

					
				}
			);
		}

	componentDidMount() {
		const user = AuthService.getCurrentUser();
		
		if (user) {
			this.setState({
				currentUser: user,
				showManagerLinks: user.roles.includes("ROLE_MANAGER"),
			});
		}
		
		ElasticSearchService.loadMakers().then(
			response => {
				this.setState({
					makers: response.data,
					isMakersLoading: false
				});
			},
			error => {
				this.setState({
					error:
						(error.response && error.response.data) ||
						error.message ||
						error.toString()
				});
			}
		);
	}

	render() {
		const { currentUser, isMakersLoading, makers } = this.state;
		return (
			<Container fluid="xxl">
				<Row>
					<Col>
					<Breadcrumb className="breadcrumb-style">
					  <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
					  <Breadcrumb.Item className="breadcrumb-item-style" active>Management</Breadcrumb.Item>
					  <Breadcrumb.Item className="breadcrumb-item-style" active>Herstellerliste</Breadcrumb.Item>
					  <Breadcrumb.Item className="breadcrumb-item-style" href="/makerDetails/new">Neuer Hersteller</Breadcrumb.Item>
					</Breadcrumb>
					</Col>
				</Row>
				<Row>
					<Col>
						<h3>Hersteller</h3>
					</Col>
				</Row>
				{!isMakersLoading ? (
					<div>
						<hr/>
						{makers && makers.map((maker, id) => (
							<div key={id} >
								<div >
									<div>
										<Row>
											<Col md="2">
												<strong>Hersteller:</strong> 
											</Col>
											<Col>
												{maker.name} {maker.vorname} {maker.ort ? <span> aus {maker.ort}</span>: ""}
											</Col>
										</Row>
										<Row>
											<Col md="2">
												<strong>Grabsteine/Werke: </strong>										
											</Col>

											<Col>
											    {maker.tombstones && maker.tombstones.map((tombstone, tsId) => (
													<Figure>
												      <Figure.Image
												        width={90}
												        height={75}
												        alt={tombstone.id}
												        src={tombstone.bilder && tombstone.bilder[0] ? tombstone.bilder[0].path : ""}
												        className="maker-img-space"
												      />
												      <Figure.Caption>
												        <a href={"/tombstoneDetails/"+tombstone.cementry+"/"+tombstone.id}>{tombstone.id}</a>
												      </Figure.Caption>
												    </Figure>												
												))}
												
											</Col>
										</Row>
										<Row>
											<Col className="col-auto me-auto">
											</Col>
											<Col className="col-auto">
												{(ACService.accessValidation(currentUser, Constants.AC_ADMIN_NAVIGATION) || ACService.accessValidation(currentUser, Constants.AC_MANAGER_NAVIGATION)) && (
													<Button href={"/makerDetails/" + maker.id} variant="link" className="btn badge-flat float-right" >Bearbeiten</Button>

												)}
												<Button onClick={() => this.createMakerPdf(maker)} >PDF</Button>
											</Col>
										</Row>
									</div>
								</div>
								<hr/>
							</div>
						))}
					</div>
				) : (
						<Row>
							<Col>Loading...</Col>
						</Row>
					)}
			</Container>
		)
	}
}
