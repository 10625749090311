import React, { useState, useEffect } from "react";
import { Container, Row, Col, Breadcrumb, Button } from "react-bootstrap";
import ElasticSearchService from "../../services/elasticSearch.service";
import AuthService from "../../services/auth.service";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import ImageGalery from "../../components/ImageGalery"
import StorageService from "../../services/storage.service";
import {DisplayTextWithLinks} from "../../helpers/createLinkFromText";


export default function Desecration() {
	const [user, setUser] = useState(AuthService.getCurrentUser());

	const [isLoadingCementries, setLoadingCementries] = useState(true);
	const [cementries, setCementries] = useState([]);
	const [selectedCementry, setSelectedCementry] = useState(StorageService.handleStorage("cementery"));

	const [successful, setSuccessful] = useState(false);
	const [isLoadingDesecrations, setLoadingDesecrations] = useState(true);
	const [desecrations, setDesecrations] = useState([]);
	const [message, setMessage] = useState("");


	function handleCementryChange(e) {

		let id = e.id;
		setSelectedCementry(id);

		localStorage.setItem("cementery", JSON.stringify(id));
		setDesecrations([]);
		setLoadingDesecrations(true)


	}

	useEffect(() => {

		let selectedCementery = StorageService.handleStorage("cementery");
		if(Array.isArray(selectedCementery)){
		
			selectedCementery = '1';
			localStorage.setItem("cementery", JSON.stringify('1'));
			
		}
		setSelectedCementry(selectedCementery);
		
		setUser(AuthService.getCurrentUser());

		if (isLoadingCementries) {

			const user = AuthService.getCurrentUser();
			let onlyPublished = user ? "0" : "-2";

			ElasticSearchService.loadCementriesForAutoComplete(onlyPublished).then(
				response => {
					setCementries(response.data);
					setLoadingCementries(false);
					setSuccessful(true)

				},
				error => {
					const resMessage =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();

					setMessage(resMessage);
					setSuccessful(false);
					setLoadingCementries(false);
				}
			);
			setSelectedCementry(selectedCementery)
		}

		if (isLoadingDesecrations) {
		console.log(selectedCementery)
				ElasticSearchService.loadAllDesecrations(selectedCementery).then(
				response => {
			
					setDesecrations(response.data);
					setSuccessful(true);
					setLoadingDesecrations(false)
				},
				error => {
					const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
					setMessage(resMessage);
					setLoadingDesecrations(false);
					setSuccessful(false)
				}
			);
		}
	}, [isLoadingDesecrations, isLoadingCementries]);
	
	function dt( text) {
		
		
	  // URLs erkennen und umwandeln
	  const linkifiedText = text.split(' ').map((word, index) => {
	    const urlPattern = new RegExp('^(https?:\\/\\/)?' + // protocol
	      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
	      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
	      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
	      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
	      '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
	    const isUrl = urlPattern.test(word);
	    return isUrl ? <a key={index} href={word} target="_blank" rel="noopener noreferrer">{word}</a> : <span key={index}>{word} </span>;
	  });

	  return linkifiedText;
	}

	return (
		<div>
			{!isLoadingCementries && !isLoadingDesecrations ? (<Container>
				<Row>
					<Col>
						<Breadcrumb className="breadcrumb-style">
							<Breadcrumb.Item href="/">Home</Breadcrumb.Item>
							<Breadcrumb.Item href="/cemeteries/">Historische Friedhöfe</Breadcrumb.Item>
							<Breadcrumb.Item href={'/cemetery/' + selectedCementry}>{cementries.find(cementery => cementery.id === selectedCementry).label}</Breadcrumb.Item>
							<Breadcrumb.Item active>Friedhofsschändungen</Breadcrumb.Item>
							{user && (
								<Breadcrumb.Item href="/desecration">Neues Ereignis anlegen</Breadcrumb.Item>
							)}


							

						</Breadcrumb>
					</Col>
				</Row>
				<Row>
					<Col><h3>Friedhofsschändungen</h3></Col>
				</Row>


				<Row><Col></Col></Row>

				<Row >
					<Col>
						<Autocomplete
							id="combo-box-cementries"
							value={cementries.find(cementery => cementery.id === selectedCementry)}
							onChange={(event, newValue) => { handleCementryChange(newValue); }}
							disableClearable={true}
							options={cementries}
							renderInput={(params) => <TextField {...params} label="Wählen Sie einen Friedhof" />}
						/>
					</Col>
				</Row>

				{desecrations.map((desecration, index) => (
					<div className="desecration-content-border" key={index}>
						<Row >
							<Col>
								<strong>Datierung:</strong> <br />{desecration.date}
							</Col>
							<Col>
								<strong>Ereignis:</strong> <br />
								<div style={{ whiteSpace: 'pre-line' }}>
									{desecration.event}
								</div>
							</Col>
						</Row>
						<Row >
							<Col>
							</Col>
							<Col>
								<strong>Quellen:</strong> <br />

								<div style={{ whiteSpace: 'pre-line' }}>
								<DisplayTextWithLinks text={desecration.source}/>
								
								</div>
							</Col>
						</Row>
						<Row >
							<Col>

							</Col>
							<Col>
								<strong>Recherche und Text:</strong> <br />

								{desecration.rights ? desecration.rights.map((entry, id) => (entry.reference === "source" ? <div key={id}>{entry.rightChoices.map((right) => (<div key={entry.id}>{right.value}{right.comment ? " | " + right.comment : ""}</div>))}</div> : "")) : ""}

							</Col>
						</Row>
						{desecration.documents.length ?
			
						<Row >
							<Col>
								<strong>Scans:</strong> <br />
								<ImageGalery photos={desecration.documents} />
							</Col>
						</Row>
						 
						
						: null}
						<Row>
							<Col className="col-auto me-auto">
							</Col>
							<Col className="col-auto">

								{user && (
									<a href={"/desecration/" + desecration.desecrationId} className="manager-link" >Ereignis bearbeiten</a>

								)}

							</Col>
						</Row>

					</div>

				))}


				{message && (
					<div className="form-group">
						<div className={successful ? "alert alert-success" : "alert alert-danger"} role="alert">{message}</div>
					</div>
				)}
			</Container>) : (
				<Container>
					<Row className="text-spacer">
						<Col>
							<strong>Seite wird geladen... Bitte warten Sie!</strong>
						</Col>

					</Row>
				</Container>
			)}
		</div>
	)
}