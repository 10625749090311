import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Breadcrumb, Form, Button } from "react-bootstrap";
import ElasticSearchService from "../../services/elasticSearch.service";
import AuthService from "../../services/auth.service";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import StorageService from "../../services/storage.service";

import html2canvas from 'html2canvas';
import jsPDF from  'jspdf';

import GoogleMapElement from "./details/GoogleMapElement";



export default function ThematicsMaps() {
	const [isLoadingCementries, setLoadingCementries] = useState(true);
	const [cementries, setCementries] = useState([]);
	const [selectedCementry, setSelectedCementry] = useState("");
	const [successful, setSuccessful] = useState(false);
	const [message, setMessage] = useState("");


	const [selectedThematicMap, setSelectedThematicMap] = useState("");
	const [thematicMaps, setThematicMaps] = useState([]);
	const [isLoadingThematicMaps, setLoadingThematicMaps] = useState(true);

	const [id1Value, setId1Value] = useState(false);
	const [id2Value, setId2Value] = useState(false);
	const [id3Value, setId3Value] = useState(false);

	const [isMapVisible, setMapVisible] = useState(false);

	const [thematicMapData, setThematicMapData] = useState()
	const [isLoadingThematicMapData, setLoadingThematicMapData] = useState(false);
	const componentRef = useRef();


	function loadThematicMap(selectedThematicMap, selectedCementry) {

		console.log("2");

		let thematicMapDataNew = {
			cemeteryId: selectedCementry,
			selectedThematicMap: selectedThematicMap,
			showTombstoneIds: id1Value,
			showDeathDate: id2Value,
			showCarvedTombstoneNumber: id3Value

		};

		console.log(thematicMapDataNew);

		ElasticSearchService.loadThematicMapData(thematicMapDataNew).then(
			response => {
				setThematicMapData(response.data);
				setLoadingThematicMapData(false);
				setSuccessful(true)
				setMapVisible(true);
			},
			error => {
				const resMessage =
					(error.response &&
						error.response.data &&
						error.response.data.message) ||
					error.message ||
					error.toString();

				setMessage(resMessage);
				setSuccessful(false);
				setLoadingThematicMapData(false);
			}
		);

	}

	function changeCheckBox(e) {

		let id = e.target.id;

		if (id === "1") {
			setId1Value(!id1Value);
		}
		if (id === "2") {
			setId2Value(!id2Value);
		}
		if (id === "3") {
			setId3Value(!id3Value);
		}
	}

	function handleCementryChange(e) {

		let id = e.id;
		setSelectedCementry(id);

		localStorage.setItem("cementery", JSON.stringify(id));

		loadThematicMap(selectedThematicMap, id);

	}

	function handleThematicMapChange(e) {

		let id = e.id;
		setSelectedThematicMap(id);
		localStorage.setItem("selectedThematicMap", JSON.stringify(id));
		loadThematicMap(id, selectedCementry);
	}

	const handlePrint = () => {
		html2canvas(componentRef.current).then((canvas) => {
		      const imgData = canvas.toDataURL('image/png');
		      const pdf = new jsPDF({
		        orientation: 'landscape', // oder 'landscape' je nach Bedarf
		        unit: 'mm',
		        format: 'a4',
		      });
		      const imgProps = pdf.getImageProperties(imgData);
		      const pdfWidth = pdf.internal.pageSize.getWidth();
		      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
		      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
		      pdf.save('seite.pdf');
		    });
	};

	useEffect(() => {

		let selectedCementery = StorageService.handleStorage("cementery");

		if (selectedCementery && selectedCementery.length > 0) {

			setSelectedCementry(selectedCementery);
		}
		else {
			selectedCementery = "1";
			localStorage.setItem("cementery", JSON.stringify("1"));
			setSelectedCementry(selectedCementery);
		}

		let selectedThematicMap = StorageService.handleStorage("selectedThematicMap");
		if (selectedThematicMap && selectedThematicMap.length > 0) {
			setSelectedThematicMap(selectedThematicMap);
		}
		else {
			selectedThematicMap = "0";
			setSelectedThematicMap(selectedThematicMap);
			localStorage.setItem("selectedThematicMap", "0");
		}



		if (isLoadingThematicMaps) {

			ElasticSearchService.loadThematicMapsForAutoComplete().then(
				response => {

					setThematicMaps(response.data);
					setLoadingThematicMaps(false);
					setSuccessful(true)

				},
				error => {
					const resMessage =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();

					setMessage(resMessage);
					setSuccessful(false);
					setLoadingThematicMaps(false);
				}
			);

		}

		if (isLoadingCementries) {
			let onlyPublished = "-2";

			ElasticSearchService.loadCementriesForAutoComplete(onlyPublished).then(
				response => {
					setCementries(response.data);
					setLoadingCementries(false);
					setSuccessful(true)

				},
				error => {
					const resMessage =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();

					setMessage(resMessage);
					setSuccessful(false);
					setLoadingCementries(false);
				}
			);

		}

		if (isLoadingThematicMaps) {
			loadThematicMap(selectedThematicMap, selectedCementery);
		}




	}, [isLoadingCementries, isLoadingThematicMaps, isLoadingThematicMaps]);

	return (

		<div>
			{!isLoadingCementries && !isLoadingThematicMaps ? (<Container>
				<Row>
					<Col>
						<Breadcrumb className="breadcrumb-style">
							<Breadcrumb.Item href="/">Home</Breadcrumb.Item>
							<Breadcrumb.Item>Historische Friedhöfe</Breadcrumb.Item>
							<Breadcrumb.Item active >Themenkarten</Breadcrumb.Item>
						</Breadcrumb>
					</Col>
				</Row>
				<Row>
					<Col><h3>Themenkarten</h3></Col>
				</Row>

				<Row><Col></Col></Row>
				<Row className="text-spacer">
					<Col>
						<Autocomplete
							id="combo-box-cementries"
							value={selectedCementry ? cementries.find(cementery => cementery.id === selectedCementry) : ""}
							onChange={(event, newValue) => { handleCementryChange(newValue); }}
							disableClearable={true}
							options={cementries}
							renderInput={(params) => <TextField {...params} label="Wählen Sie einen Friedhof" />}
						/>
					</Col>
				</Row>

				<Row className="text-spacer">
					<Col>
						<Autocomplete
							id="combo-box-thematicMaps"
							value={selectedThematicMap ? thematicMaps.find(thematicMap => thematicMap.id === selectedThematicMap) : ""}
							onChange={(event, newValue) => { handleThematicMapChange(newValue); }}
							disableClearable={true}
							options={thematicMaps}
							renderInput={(params) => <TextField {...params} label="Wählen Sie eine Themenkarte" />}
						/>
					</Col>
				</Row>
				<div ref={componentRef}>
				{isMapVisible ? (
					
					<Row className="text-spacer">
						<Col >
							
								<GoogleMapElement cemetery={thematicMapData.cemetery} tombstones={thematicMapData.tombstones} cemeteryCenter={thematicMapData.cemeteryCenter} />
							
						</Col>
					</Row>


				) : (
					<Row className="text-spacer">
						<Col>
							Wählen Sie eine Themenkarte und klicken Sie auf "Karte anzeigen".
						</Col>
					</Row>
				)}
				{selectedThematicMap === "0" && (
					<Row className="text-spacer">
						<Col>
							<strong>Legende: Zustand</strong> <br />

							<span style={{ display: 'inline-block', width: '20px', height: '10px', backgroundColor: 'green', marginRight: '5px', verticalAlign: 'middle' }}></span>
							<span>gut</span> {' '}

							<span style={{ display: 'inline-block', width: '20px', height: '10px', backgroundColor: 'yellow', marginRight: '5px', verticalAlign: 'middle' }}></span>
							<span>gefährdet</span> {' '}

							<span style={{ display: 'inline-block', width: '20px', height: '10px', backgroundColor: 'red', marginRight: '5px', verticalAlign: 'middle' }}></span>
							<span>stark gefährdet</span> {' '}

							<span style={{ display: 'inline-block', width: '20px', height: '10px', backgroundColor: 'grey', marginRight: '5px', verticalAlign: 'middle' }}></span>
							<span>Fragment</span> {' '}

							<span style={{ display: 'inline-block', width: '20px', height: '10px', backgroundColor: 'black', marginRight: '5px', verticalAlign: 'middle' }}></span>
							<span>keine Daten vorhanden</span> {' '}
						</Col>
					</Row>
				)}
				{selectedThematicMap === "1" && (
					<Row className="text-spacer">
						<Col>
							<strong>Legende: Chronologische Karte</strong> <br />

							<span style={{ display: 'inline-block', width: '20px', height: '10px', backgroundColor: '#cd5b7d', marginRight: '5px', verticalAlign: 'middle' }}></span>
							<span>1550-1599</span> {' '}

							<span style={{ display: 'inline-block', width: '20px', height: '10px', backgroundColor: '#e48544', marginRight: '5px', verticalAlign: 'middle' }}></span>
							<span>1600-1649</span> {' '}

							<span style={{ display: 'inline-block', width: '20px', height: '10px', backgroundColor: '#eda023', marginRight: '5px', verticalAlign: 'middle' }}></span>
							<span>1650-1699</span> {' '}

							<span style={{ display: 'inline-block', width: '20px', height: '10px', backgroundColor: '#2fb91f', marginRight: '5px', verticalAlign: 'middle' }}></span>
							<span>1700-1749</span> {' '}

							<span style={{ display: 'inline-block', width: '20px', height: '10px', backgroundColor: '#1fb3b9', marginRight: '5px', verticalAlign: 'middle' }}></span>
							<span>1750-1799</span> {' '}

							<span style={{ display: 'inline-block', width: '20px', height: '10px', backgroundColor: '#1fb98b', marginRight: '5px', verticalAlign: 'middle' }}></span>
							<span>1800-1849</span> {' '}

							<span style={{ display: 'inline-block', width: '20px', height: '10px', backgroundColor: '#b91fb6', marginRight: '5px', verticalAlign: 'middle' }}></span>
							<span>1850-1899</span> {' '}

							<span style={{ display: 'inline-block', width: '20px', height: '10px', backgroundColor: '#f07ced', marginRight: '5px', verticalAlign: 'middle' }}></span>
							<span>ab 1900</span> {' '}
						</Col>
					</Row>
				)}
				{selectedThematicMap === "2" && (
					<Row className="text-spacer">
						<Col>
							<strong>Legende: Schändungskarte</strong> <br />

							<span style={{ display: 'inline-block', width: '20px', height: '10px', backgroundColor: 'red', marginRight: '5px', verticalAlign: 'middle' }}></span>
							<span>Schäden im Kontext von Schändungen</span> {' '}

							<span style={{ display: 'inline-block', width: '20px', height: '10px', backgroundColor: 'yellow', marginRight: '5px', verticalAlign: 'middle' }}></span>
							<span>Konstruktive Schäden</span> {' '}

							<span style={{ display: 'inline-block', width: '20px', height: '10px', backgroundColor: 'grey', marginRight: '5px', verticalAlign: 'middle' }}></span>
							<span>Schäden am Stein</span> {' '}

							<span style={{ display: 'inline-block', width: '20px', height: '10px', backgroundColor: 'black', marginRight: '5px', verticalAlign: 'middle' }}></span>
							<span>keine Daten vorhanden</span> {' '}
						</Col>
					</Row>
				)}

				{selectedThematicMap === "3" && (
					<div>
						<Row className="text-spacer">
							<Col>
								<strong>Legende: Datenkarte</strong> <br />


							</Col>
						</Row>
						<Row className="text-spacer">
							<Col>
								<Form.Text className="text-muted"><strong>Datenfelder</strong></Form.Text>
								<Form.Group >
									<Form.Check inline label="Grabstein-ID" checked={id1Value} id="1" onChange={(e) => changeCheckBox(e)} />
									<Form.Check inline label="Sterbedatum" checked={id2Value} id="2" onChange={(e) => changeCheckBox(e)} />
									<Form.Check inline label="Eingemeißelte Grabsteinnummer" id="3" checked={id3Value} onChange={(e) => changeCheckBox(e)} />
								</Form.Group>
							</Col>
						</Row>
					</div>
				)}

				{selectedThematicMap === "4" && (
					<Row className="text-spacer">
						<Col>
							<strong>Legende: Geschlechter</strong> <br />
							<span style={{ display: 'inline-block', width: '20px', height: '10px', backgroundColor: 'red', marginRight: '5px', verticalAlign: 'middle' }}></span>
							<span>Weiblich</span> {' '}

							<span style={{ display: 'inline-block', width: '20px', height: '10px', backgroundColor: 'blue', marginRight: '5px', verticalAlign: 'middle' }}></span>
							<span>Männlich</span> {' '}

							<span style={{ display: 'inline-block', width: '20px', height: '10px', backgroundColor: 'grey', marginRight: '5px', verticalAlign: 'middle' }}></span>
							<span>Keine Angaben</span> {' '}

							<span style={{ display: 'inline-block', width: '20px', height: '10px', backgroundColor: '#a751ac', marginRight: '5px', verticalAlign: 'middle' }}></span>
							<span>zwei Personen</span> {' '}

							<span style={{ display: 'inline-block', width: '20px', height: '10px', backgroundColor: 'black', marginRight: '5px', verticalAlign: 'middle' }}></span>
							<span>keine Personendaten bekannt</span> {' '}
						</Col>
					</Row>
				)}
</div>
				<Row className="text-spacer">
					<Col>
						<Button className="w-100" onClick={() => { handlePrint() }}>Karte als PDF speichern</Button>				     

					</Col>
				</Row>



				{message && (
					<div className="text-spacer">
						<div className={successful ? "alert alert-success" : "alert alert-danger"} role="alert">{message}</div>
					</div>
				)}


			</Container>) : (
				<Container>
					<Row className="text-spacer">
						<Col>
							<strong>Daten werden geladen</strong>
						</Col>

					</Row>
				</Container>
			)}
		</div>

	)
}