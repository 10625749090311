"use client";

import React, { useState, useCallback, useMemo, } from 'react'
import * as Constants from "../../../components/constants";
import { GoogleMap, useJsApiLoader, Polygon, InfoWindow } from '@react-google-maps/api';

import { personToString } from '../../../helpers/util'
import { Row, Col } from "react-bootstrap";


export default function GoogleMapElement(props) {

	const { cemetery, tombstones, cemeteryCenter } = props;

	const [activeMarker, setActiveMarker] = useState(null);

	const containerStyle = {
		height: '800px'
	};


	const mapOptions = useMemo(() => ({
		"version": "weekly",
		id: 'google-map-script',
		googleMapsApiKey: Constants.GOOGLE_API_KEY,
		libraries: ['places', 'geometry', 'maps', 'marker'],
		language: 'de',
		region: 'DE',
		"mapIds": [],
		"nonce": "",
		"url": "https://maps.googleapis.com/maps/api/js",
		"authReferrerPolicy": "origin"
	}), []);

	const { isLoaded } = useJsApiLoader(mapOptions);
	const [map, setMap] = useState(null)
	const onLoad = useCallback(function callback(map) {

	}, [])

	const onUnmount = useCallback(function callback(map) { setMap(null) }, [])

	if (!isLoaded) {
		return <div>Karte wird geladen...</div>
	}


	function getCenterPosition() {
		return cemeteryCenter;
	}

	function getTombstoneColor(thematicDataFlag) {

		if (thematicDataFlag === "red") {
			return Constants.tombstoneRedColor;
		}
		if (thematicDataFlag === "green") {
			return Constants.tombstoneGreenColor;
		}
		if (thematicDataFlag === "yellow") {
			return Constants.tombstoneYellowColor;
		}
		if (thematicDataFlag === "grey") {
			return Constants.tombstoneGreyColor;
		}
		if (thematicDataFlag === null) {
			return Constants.tombstoneBlackColor;
		}
		if (thematicDataFlag === "blue") {
			return Constants.tombstoneBlueColor;
		}
		if (thematicDataFlag === "purple") {
			return Constants.tombstonePurpleColor;
		}
		if (thematicDataFlag === "color15501599") {
			return Constants.color15501599;
		}
		if (thematicDataFlag === "color16001649") {
			return Constants.color16001649;
		}
		if (thematicDataFlag === "color16501699") {
			return Constants.color16501699;
		}
		if (thematicDataFlag === "color17001749") {
			return Constants.color17001749;
		}
		if (thematicDataFlag === "color17501799") {
			return Constants.color17501799;
		}
		if (thematicDataFlag === "color18001849") {
			return Constants.color18001849;
		}
		if (thematicDataFlag === "color18501899") {
			return Constants.color18501899;
		}
		if (thematicDataFlag === "color1900") {
			return Constants.color1900;
		}
		return Constants.tombstoneOptions;
	}

	const handleActiveMarker = (marker) => {

		if (marker === activeMarker) {
			return;
		}
		setActiveMarker(marker);
	};


	const getTombstonePolygon = tombstone => {

		if (tombstone.weltkoordinaten !== null && tombstone.weltkoordinaten.features !== null) {
			if (tombstone.weltkoordinaten.features.length > 0) {
				let tombstoneCoordinates = tombstone.weltkoordinaten.features[0].geometry.coordinates[0];
				let link = '/tombstoneDetails/' + tombstone.cementry + '/' + tombstone.id

				return (<div key={'P' + tombstone.id}><Polygon
					onMouseOver={() => { handleActiveMarker(tombstone.id) }}
					onMouseOut={() => { handleActiveMarker(null) }}
					onClick={() => { window.open(link, '_self') }}
					onLoad={onLoad}
					paths={tombstoneCoordinates}
					options={getTombstoneColor(tombstone.thematicDataFlag)} />
					{activeMarker === tombstone.id ? (
						<InfoWindow onCloseClick={() => setActiveMarker(null)} position={tombstoneCoordinates[0]}>
							<div>
								<Row>
									<Col className="col-auto me-auto" >
										<img width={64} height={64} className="mr-3" src={tombstone.bilder && tombstone.bilder[0] && tombstone.bilder[0].tumbnailImagePath ? tombstone.bilder[0].tumbnailImagePath : ""} alt="kein Bild vorhanden" />
									</Col>
									<Col>
										<p><strong> <a href={"/tombstoneDetails/" + tombstone.cementry + "/" + tombstone.grabstein.grabsteinnummer}>Grabstein-ID: {tombstone.grabstein.grabsteinnummer}</a></strong></p>
										{tombstone.persons.map((person, id3) => (
											<div key={id3}>
												<a href={"/personDetails/" + tombstone.cementry + "/" + person.id} >{personToString(person)}</a>
											</div>
										))}
									</Col>
								</Row>
								<Row>
									<Col className="col-auto me-auto" >

									</Col>
									<Col className="col-auto">
										{tombstone.displayThematicData}
									</Col>
								</Row>
							</div>
						</InfoWindow>
					) : null}

				</div>)
			}
		}
	}

	return (
		<div>
			{isLoaded ? (
				<GoogleMap
					mapContainerStyle={containerStyle}
					center={getCenterPosition(cemetery)}
					onLoad={onLoad}
					zoom={19}
					onUnmount={onUnmount}
					options={Constants.mapStyles}
					streetViewControl={false}
					disableDefaultUI={false}
				>
					{cemetery.coordinates != null && cemetery.coordinates.features != null && (
						<Polygon onLoad={onLoad} path={cemetery.coordinates.features[0].geometry.coordinates[0]} options={Constants.options} />
					)}
					{cemetery.coordinates != null && cemetery.coordinates.features != null && (
						<Polygon onLoad={onLoad} path={cemetery.coordinates.features[0].geometry.coordinates[1]} options={Constants.options} />
					)}

					{tombstones.map(function(tombstone) { return getTombstonePolygon(tombstone); })}

				</GoogleMap>
			) : <></>}



		</div>
	)
}







