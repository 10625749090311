import React, { Fragment, useState, useEffect } from "react";

import { Row, Col } from "react-bootstrap";

import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import LensBlurIcon from '@mui/icons-material/LensBlur';

import * as Constants from "../../components/constants";
import { getStringValue } from "../../helpers/util.js";
import ACService from "../../helpers/accessValidation.js";
import AuthService from "../../services/auth.service";
import ElasticSearchService from "../../services/elasticSearch.service";

export default function CopyRight(props) {
	
	const [user] = useState(AuthService.getCurrentUser());
	
	const { readOnly, label, fieldName, rights, onChange, cementeryName } = props;

		
	const [licenseSelection, setLicenseSelection] = useState("");
	const [licenseEntered, setLicenseEntered] = useState("");
	const [licenses, setLicenses] = useState([]);
	
	const [isReadOnly, setIsReadOnly] = useState(true);
		
	const [message, setMessage] = useState("");
	const [successful, setSuccessful] = useState("");
	
	const CHOICE_GROUP = "Urheberrechte und Lizenzen";
	const CHOICE_GROUP_TYPE = "rights";
	
	function handleChangeLicenseSelection(event, updatedLicenseId) {
		
		setLicenseSelection(updatedLicenseId);
		setLicenseEntered("");
		
		let currentRights = getUpdatedLicenses(updatedLicenseId);
			
		onChange(currentRights);
	}
	
	function getUpdatedLicenses(licenseId) {
		
		let currentRights = rights === undefined ? [] :[...rights];
		
	
		// does a license for the current fieldName exist? 
		let index = currentRights.findIndex((right) => right.reference === fieldName);
		if (index === -1) {
			
			currentRights.push({
				id: licenseId,
				reference: fieldName
			});
		} else {
		
			let license = currentRights[index];

			if (license.id !== licenseId) {
				
				license.id = licenseId;
			}	
		}
		
		return currentRights;
	}
	
	function handleChangeLicenseEntered(event, updatedCopyrightString) {
		
		setLicenseEntered(updatedCopyrightString);
	}
		
	function handleAddCopyright() {
			
		let license = {
			id: CHOICE_GROUP + "_" + licenseEntered.replace(/ /g, ""),
			type: CHOICE_GROUP_TYPE,
			value: licenseEntered,
			comment: licenseEntered
		};
			
		ElasticSearchService.saveChoice(license).then(
			response => {	
				
			
				
				let currentLicenses = [...licenses];
				currentLicenses.push(license);
				
				setLicenseSelection(license.id);
				
				setLicenses(currentLicenses);
				setLicenseEntered("");
				
			},
			error => {
				const resMessage =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();
	
				setMessage(resMessage);
				setSuccessful(false);
			}
		);
	}
	
	function handleResetLicenseSelection() {
		
		setLicenseSelection("");
		setLicenseEntered("");
		
		let currentRights = rights === undefined ? [] :[...rights];
		
		// does a license for the current fieldName exist? 
		let index = currentRights.findIndex((right) => right.reference === fieldName);
		if (index !== -1) currentRights.splice(index, 1);
		
		onChange(currentRights);
	}
	
	useEffect(() => {
		

		setIsReadOnly(readOnly && !ACService.accessValidation(user, Constants.AC_COPYRIGHTS, Constants.AC_MANAGER, Constants.AC_ADMIN_NAVIGATION), cementeryName);
		
		ElasticSearchService.loadChoices().then(
			response => {	
				let data = response.data;
				
				let currentChoices = data[CHOICE_GROUP];
			
				setLicenses(currentChoices);
				let index = rights !== undefined ? rights.findIndex((right) => right.reference === fieldName) : -1;
				if (index !== -1) setLicenseSelection(rights[index].id);
			},
			error => {
				const resMessage =
						(error.response &&
							error.response.data &&
							error.response.data.message) ||
						error.message ||
						error.toString();
	
				setMessage(resMessage);
				setSuccessful(false);
			}
		);	
		
	}, [user, readOnly]);
	
	return (
		<Row >
			<Col >
				<Stack direction="row">
					<Autocomplete 
						
						value={licenses.find(license => license.id === licenseSelection) !== undefined ? licenses.find(license => license.id === licenseSelection) : null}
						onChange={(event, license) => handleChangeLicenseSelection(event, license.id)}
						disableClearable = {true} 
						id="combo-box-licenses"	
						
						fullWidth 
						disabled={isReadOnly}
							
						isOptionEqualToValue={(option, value) => option.id === value.id}
						getOptionLabel={(option) => option !== "" ? option.value : ""}
						options={licenses}
						renderInput={(params) => {
							
							return (<TextField {...params} label={label ? label : "Bitte wählen Sie den Urheber aus"} />);
						}} 
						
						onInputChange={(event, newInputValue) => {
							
							let eventType = event != null ? getStringValue(event.type, "") : "";
							if (eventType === "change") {
								handleChangeLicenseEntered(event, newInputValue);	
							}
						}}
						
						noOptionsText="Keine Lizenz mit diesem Namen"
	
					/>
					
					{!isReadOnly && (
						<Fragment>
							{licenseEntered.length > 0 ? (
								<Tooltip title="Lizenz hinzufügen" arrow placement="left">
									<span>
										<IconButton onClick={() => handleAddCopyright()} ><AddIcon /></IconButton>
									</span>
								</Tooltip>
							) : licenseSelection.length > 0 ? (
								<Tooltip title="Lizenz zurücksetzen" arrow placement="left">
									<span>
										<IconButton onClick={() => handleResetLicenseSelection()} ><CancelPresentationIcon /></IconButton>
									</span>
								</Tooltip>
							) : (
								<Fragment>
								
									<Tooltip title="Keine Aktion möglich. Bitte geben Sie eine neue Lizenz ein oder wählen eine aus der Liste." arrow placement="left">
										<span>
											<IconButton disabled={true} ><LensBlurIcon /></IconButton>
										</span>
									</Tooltip>
								
								</Fragment>
							)}
							
						</Fragment>
					)}
					
					{message && (
						<div className="form-group">
							<div className={successful ? "alert alert-success": "alert alert-danger"} role="alert">{message}</div>
						</div>
					)}
				</Stack>
			</Col>		
		</Row>
	);
}